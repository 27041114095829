@import 'variables';

body{
    background: #eeeeee;
}

.bg-cardLogin{
   background: var(--primario);
}

.bg-pruebas{
 background: var(--primario);
background: linear-gradient(90deg, rgba(2, 39, 60,1) 0%, rgba(2, 39, 60,1) 50%, rgba(255,255,255,1) 50%);
    border-radius: 2.5rem;
}

@media (max-width: 767px) {
 .bg-pruebas{
   background: var(--primario);
   background: linear-gradient(180deg, rgba(2, 39, 60,1) 0%, rgba(2, 39, 60,1) 50%, rgba(255,255,255,1) 50%);
   border-radius: 2.5rem;
}
 }

.btn-primary-custom{
   color: #ffffff;
   background-color: var(--naranja);
}

.btn-primary-custom:hover{
   color: #383737;
   background-color: #cd5421;
}

.bg-light-custom{
  background-color: #ededed;
}
